import React, { Component } from 'react';
import {
	Modal,
	FormGroup,
	FormLabel,
	FormControl,
	Form
} from 'react-bootstrap';
import { Button } from '@material-ui/core';
import setVal from 'set-value';
import ReactPasswordStrength from 'react-password-strength';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import TimezonePicker, { timezones } from 'react-timezone';
import CommonEditAlert from '../error/CommonEditAlert';
import CommonErrorAlert from '../error/CommonErrorAlert';
import Api from '../../api/Api';
import SegmentUtil from '../../util/SegmentUtil';

export default class EditUser extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.state.user = props.user;
	}

	componentDidMount() {
		Api.getRoles().then(res => {
			const roles =
				this.state.user.role === 'superadmin'
					? res.data
					: res.data.filter(role => role.name !== 'superadmin');
			this.setState({ roles });
		});
	}

	show = () => {
		this.setState({ show: true });
	};

	setUser = user => {
		this.setState({ user });
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	handleCloseWithoutSave = () => {
		this.resetState();
	};

	resetState = () => {
		this.setState({
			show: false,
			showAlert: false,
			dirty: false,
			error: false,
			errormessage: ''
		});
	};

	handleSave = () => {
		if (this.state.user.new) {
			Api.createUser(this.state.user)
				.then(() => {
					if (this.props.callback) {
						this.props.callback();
					}
					this.resetState();
				})
				.catch(() => {
					this.setState({
						error: true,
						errormessage: 'There was a problem creating a new user'
					});
				});
		} else {
			if (this.state.rolechanged) {
				SegmentUtil.track(SegmentUtil.actions.rolechanged);
			}
			Api.updateUser(this.state.user)
				.then(() => {
					if (this.props.callback) {
						this.props.callback();
					}
					this.resetState();
				})
				.catch(() => {
					this.setState({
						error: true,
						errormessage: 'There was a problem updating this user'
					});
				});
		}
	};

	handleChange = event => {
		const { user } = this.state;
		setVal(user, event.target.id, event.target.value);
		this.setState({ user, dirty: true });
		this.checkEmailExists();
	};

	handleRoleChange = event => {
		const { user } = this.state;
		setVal(user, 'role', event.currentTarget.id);
		this.setState({ user, dirty: true, rolechanged: true });
	};

	handleTimezoneChange = event => {
		const tzobj = timezones.filter(tz => tz.name === event);
		const { user } = this.state;
		user.timezone = tzobj[0].name;
		user.timezoneoffset = tzobj[0].offset;
		this.setState({ dirty: true, user });
	};

	createRoleRadios() {
		const options = [];
		this.state.roles.forEach(role => {
			options.push(
				<Form.Check
					onChange={this.handleRoleChange}
					checked={this.state.user.role == role.name}
					className='col-3'
					custom
					name='role'
					key={options.length}
					inline
					label={role.name}
					type='radio'
					id={`${role.name}`}
				/>
			);
		});
		return options;
	}

	handlePasswordChange = event => {
		const { user } = this.state;

		user.passvalid = event.isValid;
		user.passscore = event.score;
		user.password = event.password;

		this.setState({ user, dirty: true });
	};

	checkEmailExists = () => {
		const regex = /\S+@\S+\.\S+/;
		if (regex.test(this.state.user.email) && this.state.user.new) {
			Api.checkUserExists(this.state.user.email).then(res => {
				const { user } = this.state;
				user.exists = res.data.exists;
				this.setState({ user });
			});
		}
	};

	validateForm = () => {
		if (this.state.user.new) {
			if (!this.state.user.passvalid) {
				return false;
			}
			if (this.state.user.password !== this.state.user.confirmpass) {
				return false;
			}
		}
		// test the email address last
		if (this.state.user.exists) {
			return false;
		}
		const regex = /\S+@\S+\.\S+/;
		return regex.test(this.state.user.email);
	};

	onHide = () => false;

	// TODO:  Add time zone to user create / edit dialogs
	render() {
		if (!this.state.user) {
			return <div />;
		}
		return (
			<Modal
				show={this.state.show}
				onHide={this.onHide}
				dialogClassName='edit-modal'
			>
				<Modal.Header>
					<Modal.Title>
						{this.state.user.new ? 'Create Member' : 'Edit Member'}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{this.state.showAlert ? (
						<CommonEditAlert
							handleDismissAlert={this.handleDismissAlert}
							handleClose={this.handleClose}
							handleCloseWithoutSave={this.handleCloseWithoutSave}
						/>
					) : null}
					{this.state.error ? (
						<CommonErrorAlert
							message={this.state.errormessage}
							handleCloseWithoutSave={this.handleCloseWithoutSave}
						/>
					) : null}
					<Form>
						<FormGroup className='col-12 col-md-6' controlId='first_name'>
							<FormLabel>
								First name <span className='required-fields'>*</span>{' '}
							</FormLabel>
							<FormControl
								required
								type='text'
								value={this.state.user.first_name}
								onChange={this.handleChange}
							/>
						</FormGroup>
						<FormGroup className='col-12 col-md-6' controlId='last_name'>
							<FormLabel>
								Last name <span className='required-fields'>*</span>{' '}
							</FormLabel>
							<FormControl
								required
								onChange={this.handleChange}
								type='text'
								value={this.state.user.last_name}
							/>
						</FormGroup>
						<FormGroup className='col-12 col-md-6' controlId='email'>
							<FormLabel>Email address</FormLabel>
							<FormControl
								readOnly={!this.state.user.new}
								autoFocus
								type='text'
								value={this.state.user.email}
								onChange={this.handleChange}
							/>
						</FormGroup>
						<FormGroup className='col-12 no-margin'>
							<div className='horizontal-separator' />
						</FormGroup>
						{this.state.user.new ? (
							<>
								<FormGroup className='col-12 col-md-6' controlId='password'>
									<FormLabel>Password</FormLabel>
									<ReactPasswordStrength
										className='customClass'
										minLength={5}
										minScore={2}
										scoreWords={['Weak', 'Weak', 'Okay', 'Good', 'Great']}
										tooShortWord='Short'
										changeCallback={this.handlePasswordChange}
										inputProps={{
											name: 'password',
											autoComplete: 'off',
											className: 'form-control'
										}}
									/>
								</FormGroup>
								<FormGroup className='col-12 col-md-6' controlId='confirmpass'>
									<FormLabel>Confirm Password</FormLabel>
									<FormControl
										className='confirm-password'
										type='password'
										autoComplete='new-password'
										value={this.state.user.confirmpass}
										onChange={this.handleChange}
									/>
								</FormGroup>
								<FormGroup className='col-12 no-margin'>
									<div className='horizontal-separator' />
								</FormGroup>
							</>
						) : null}
						<FormGroup className='col-12 col-md-6' controlId='date_of_birth'>
							<FormLabel>Date of Birth</FormLabel>
							<div className='profile-datepicker'>
								<DayPickerInput
									value={this.state.user.date_of_birth}
									onDayChange={this.handleDateChange}
								/>
							</div>
						</FormGroup>
						<FormGroup className='col-12 col-md-6'>
							<FormLabel>Timezone</FormLabel>
							<div>
								<TimezonePicker
									className='timezone'
									onChange={this.handleTimezoneChange}
									value={this.state.user.timezone}
									inputProps={{ placeholder: 'Select Timezone...' }}
								/>
							</div>
						</FormGroup>
						<FormGroup className='col-12 no-margin'>
							<div className='horizontal-separator' />
						</FormGroup>
						<FormGroup className='col-12 ' controlId='role'>
							<FormLabel className='role'>Role</FormLabel>
							<div key='custom-inline-radio'>
								{this.state.roles ? this.createRoleRadios() : null}
							</div>
						</FormGroup>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='contained'
						color='primary'
						onClick={this.handleSave}
						disabled={!this.validateForm()}
					>
						{this.state.user.new ? 'Create' : 'Save'}
					</Button>
					<Button
						variant='contained'
						color='primary'
						onClick={this.handleClose}
						className='discard'
					>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}
