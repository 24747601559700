import React, { Component } from 'react';
import { Icon } from '@mdi/react';
import { Box, MenuItem } from '@material-ui/core';
import { connectStoreon } from 'storeon/react';
import { ObservationSummary } from '../components/observations/ObservationSummary';
import ObservationDrawer from '../components/observations/ObservationDrawer';
import DecoratedSelect from '../components/common/form/DecoratedSelect';
import withErrorHandler from '../components/error/WithErrorHandler';
import global from '../res/global';
import icons from '../style/icons';
import SegmentUtil from '../util/SegmentUtil';

class Observations extends Component {
	actions = [
		{
			icon: <Icon size={1} path={icons.add} />,
			name: 'Add an Observation',
			callback: () => {
				SegmentUtil.track(SegmentUtil.actions.addobservation, {
					category: 'app',
					source: 'analytics'
				});
				this.props.dispatch('navstate/update', {
					drawerOpen: true,
					drawerContent: (
						<ObservationDrawer
							observation={{ observationtime: new Date(), observation: {} }}
						/>
					)
				});
			}
		}
	];

	constructor(props) {
		super(props);
		this.state = {
			showDrawer: false,
			scopedOptions: [],
			scope: { id: 0, value: 'all', label: 'All', icon: '' }
		};
	}

	componentDidMount() {
		this.props.dispatch('navstate/update', { pageTitle: 'Observations' });
		this.props.dispatch('locations/refresh');
		this.props.dispatch('spaces/refresh');
		this.props.dispatch('sensors/refresh');
		this.props.dispatch('levels/refresh');
		this.setState({ scope: global.scopeOptions[0] }, this.loadObservations);
		this.props.setFab(this.actions);
	}

	openObservationDrawer = obs => {
		const component = <ObservationDrawer observation={{ ...obs }} />;
		this.props.dispatch('navstate/update', {
			drawerOpen: true,
			drawerContent: component
		});
	};

	closeDrawer = () => {
		this.setState({ showDrawer: false });
	};

	changeScope = ev => {
		this.props.dispatch('observations/refresh', {
			scope: ev.target.value,
			typeObs: 'general'
		});
	};

	loadObservations = () => {
		this.props.dispatch('observations/refresh', {
			scope: this.state.scope.value,
			typeObs: 'general'
		});
	};

	render() {
		if (!this.state.scope) {
			return <></>;
		}
		return (
			<Box height='100%' display='flex' flexDirection='column' padding={2}>
				<Box>
					<Box width='25%'>
						<DecoratedSelect
							conservField='scope'
							id='scope'
							label='Scope'
							aria-label='observation scope'
							onChange={this.changeScope}
							value={this.props.observations.scope}
							editing
						>
							{global.scopeOptions.map(opt => (
								<MenuItem key={opt.value} value={opt.value}>
									{opt.label}
								</MenuItem>
							))}
						</DecoratedSelect>
					</Box>
				</Box>
				<Box height='100%' width='100%' style={{ overflowY: 'auto' }}>
					<Box>
						<ObservationSummary
							scope={this.props.observations.scope}
							scopedId={this.state.scopedId}
							observations={this.props.observations.list}
							openDrawer={this.openObservationDrawer}
							pages={this.props.observations.pages}
							total={this.props.observations.total}
						/>
					</Box>
				</Box>
			</Box>
		);
	}
}

export default connectStoreon(
	'observations',
	'locations',
	'spaces',
	'sensors',
	withErrorHandler(Observations)
);
